.header-mission-pic {
  height: 650px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../assets//img/about-page/About\ Slider\ \(1\)\ copy.jpg');
  /* background-image: url('../assets/img/about-page/MicrosoftTeams-image\ \(24\).png'); */
  /* background-image: url('../assets//img//Our_mission//header.svg'); */
}
@media (min-width: 768px) and (max-width: 1023px) {
    .header-mission-pic {
      width: 100% !important;
      background-size: 100% 100% !important;
      background-size: cover;
    }
  }
  @media (min-width: 390px) and (max-width: 844px) {
    .header-mission-pic {
      width: 100% !important;
      background-size: 100% 100% !important;
      background-size: cover;
    }
  }
  @media (min-width: 360px) and (max-width: 740px) {
    .header-mission-pic {
      width: 100% !important;
      background-size: 100% 100% !important;
      background-size: cover;
    }
  }

.iframe {
  min-height: 400px;
  border-radius: 20px;
}
