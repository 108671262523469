.support-y {
}

.Success {
  padding-top: 60px;
}

.Success h2 {
  font-family: Roboto;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 2px;
  text-align: left;
  color: #1b4079;
}
.Support {
  font-family: Roboto;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 2px;
  text-align: left;
  color: #1b4079;
}

.Success p {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 2px;
  text-align: left;
  color: black;
}
