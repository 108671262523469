@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&family=Poppins&family=Roboto:wght@500;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.main-img {
  background-image: url("../assets//img/New_Header_Pic/hhhe.png");
  background-size: 100% 100%;
  min-height: 860px;
  width: 100%;
}

.header_h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 65px !important;
  line-height: 95px !important
;
  /* or 100% */

  display: flex;
  align-items: center !important;
  letter-spacing: -3px !important;

  color: #ffffff !important;
}
.main-content {
  min-height: 700px;
  display: flex;
  align-items: center;
}

.header_content {
  /* padding-top: 15px; */
  /* padding-left: 90px; */
}

.header_content p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #f5f5f5;
}
.header_content h1 {
  font-family: "Roboto";
  font-size: 80px;
  font-weight: 600;
  line-height: 100px;
  letter-spacing: -3px;
  text-align: left;
  color: #ffffff;
}

.header-main {
  padding-top: 230px;
}
.header-learn-more-btn Button {
  width: 180.890625px;
  height: 58px;

  border-radius: 3px;

  font-family: "Roboto";
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 3.5999999046325684px;
  text-align: center;
  border: none;
  outline: none;
  color: #ffffff;
}
.header-learn-more-btn Button:hover {
  background: #4d7c8a;
  color: #ffffff;
  outline: none;
  border: none;
}
.its-founder {
  padding-left: 10px;
}
