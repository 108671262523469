.get-in-touch-main {
  background-color: #ffffff;
}

.header-ContactUs-pic {
  height: 700px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets//img/Contact-Us/MicrosoftTeams-image\ \(30\).png");
}
.Get-In-Touch h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  line-height: 62px;
  color: #1b4079;
  margin-bottom: 50px;
}

.main-input-and-icon-div {
  height: 53px;

  background: #ffffff;
  border: 1px solid #e3e9f4;
  border-radius: 8px;
}

.contact-input {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  gap: 32px;
}
.input-box input {
  width: 100%;
  border: none;
  outline: red;
  padding-left: 20px;
  height: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1b4079;
}
.icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-icon {
  height: 20px;
  width: 20px;
  background-image: url("../assets/img/Contact-Us/Vector\ \(18\).svg");
  background-size: 100% 100%;
}

.subject {
  height: 20px;
  width: 20px;
  background-image: url("../assets/img/Contact-Us/subject.svg");
  background-size: 100% 100%;
}

.phone-icon {
  height: 20px;
  width: 20px;
  background-image: url("../assets/img/Contact-Us/phone-icon.svg");
  background-size: 100% 100%;
}

.email-icon {
  height: 80px;
  width: 80px;
  background-image: url("../assets/img/Contact-Us/email-icon.svg");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
  background-size: 100% 100%;
  top: calc(50% - 40px / 2);
  background: #1b4079;
  border-radius: 8px;
}

.text-area {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-left: 15px;
}
.text-area textarea {
  height: 120px;
  width: 710px;
  max-width: 100%;
  margin-left: 4px;
  background: #ffffff;
  border: 1px solid #e3e9f4;
  border-radius: 8px;
}

.contact-side-bar {
  min-height: 399px;
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  align-items: center;
}

.content-side-bar {
  min-height: 460px;
  left: 0px;
  right: 0px;
  top: 0px;

  background: #ffffff;
}
.location-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
  background-image: url("../assets//img/Contact-Us/Vector\ \(22\).svg");
  background-size: 100% 100%;
  top: calc(50% - 40px / 2);
  background: #1b4079;
  border-radius: 8px;
}

.visit-office h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  padding-left: 10px;
  padding-top: 35px;
  color: #222222;
}
.main-contact-centent p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  padding-top: 5px;
  padding-left: 20px;
  color: #555555;
}

.hr-box {
  display: flex;
  justify-content: center;
}
.hr-line2 {
  border-bottom: 1px solid #e3e9f4;
  width: 89%;
}

.map-container {
  overflow: hidden;
}
.map-container iframe {
  min-height: 600px;
  width: 99.3%;
}

.contact-submite-btn button {
  width: 155px;
  height: 58px;
  background: #1b4079;
  border-radius: 3px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 60px;
  border: none;
  outline: none;
  color: #ffffff;
}
