@media (max-width: 600px) {
  .main_bg {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .NewUpdateDetail {
    height: 308px !important;
    width: 100%;
    margin-top: -60px;
    background-repeat: no-repeat;
    background-size: cover !important;
    /* background-image: url(http://localhost:3000/static/media/MicrosoftTeams-image%20(38).77cd898….png); */
  }

  .ourAgency-main {
    min-height: 480px;

    background-size: 100% 100%;
    background-color: #1b4079 !important;
  }

  .out-storyy {
    min-height: 290px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view-all {
    display: flex;
    justify-content: center;
  }

  .card-content h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 38px;
    padding-left: 8px;
    color: #000000;
  }

  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    margin-left: 8px;
  }

  .footer ul {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    padding-top: 30px;
    margin-left: -41px;
  }

  .contentt-cc h2 {
    font-family: "Roboto";
    font-size: 31px !important;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
  }

  .contentt-cc p {
    font-family: "Roboto";
    font-size: 15px !important;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #ffffff;
    margin-top: -20px;
  }

  .Success {
    padding-top: 0;
  }

  .Success h2 {
    font-family: "Roboto";
    font-size: 34px !important;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 2px;
    text-align: center !important;
    color: #1b4079;
    margin-bottom: 20px;
  }

  .Success p {
    font-family: "Roboto";
    font-size: 17px !important;
    font-weight: 400;
    line-height: 30px !important;
    letter-spacing: 2px;
    text-align: center !important;
    color: black;
  }

  .nav-link {
    height: 48px;
    border-bottom: 1px solid;
    box-shadow: 5px;
    /* text-align: center; */
    width: 100%;
  }

  .support-y {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .support-y h1 {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 31px !important;
    letter-spacing: 0px !important;
    color: #1b4079 !important;
    margin-bottom: 30px;
  }

  /* All Moblie Screen */

  .read-more {
    display: flex;
    justify-content: center;
  }

  .read-more button {
    width: 130px !important;
    height: 52.39px !important;
    background: #1b4079;
    border-radius: 3px;
    margin-left: 0px !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #f5f5f5;
    font-size: 13px;
    border: none;
  }

  .display {
    display: none;
    border-style: none;
  }

  .content-box {
    margin-top: -35px;
  }
  .join-ssf-btn Button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    /* padding: 18px 22px; */
    margin-bottom: 10px;
    margin-top: 14px;
    margin-left: 4px;
  }

  .logo-div {
    display: flex;
    justify-content: end;
    margin-left: 15px;
  }

  .who-we h2 {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 31px !important;
    line-height: 41px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: 0px !important;
    color: #1b4079 !important;
    margin-bottom: 30px;
  }

  .its-founder {
    padding-left: 10px;
    text-align: center;
  }

  .paragraph {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 25px !important;
    color: #535353 !important;
    letter-spacing: 0px;
    /* margin-top: 60px; */
  }

  .whowe-h4 h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #000000;
  }

  .education-img {
    background-image: url("../assets/img/education.svg");
    width: 48px !important;
    height: 64px;
    background-size: 100% 100%;
    margin-top: 0px;
  }

  .health-img {
    background-image: url("../assets/img/Health.svg");
    width: 47px !important;
    height: 64px;
    background-size: 100% 100%;
    margin-left: 3px;
    margin-top: 4px;
  }

  .food-img {
    background-image: url("../assets/img/Food.svg");
    width: 51px !important;
    height: 64px;
    background-size: 100% 100%;
    margin-top: 6px;
  }

  .whowe-h4 h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
    margin-top: 8px;
    color: #000000;
  }

  .education-img {
    background-image: url("../assets/img/education.svg");
    width: 46px !important;
    height: 64px;
    background-size: 100% 100%;
  }

  .health-img {
    background-image: url("../assets/img/Health.svg");
    width: 46px !important;
    height: 64px;
    background-size: 100% 100%;
  }

  .food-img {
    background-image: url("../assets/img/Food.svg");
    width: 49px !important;
    height: 64px;
    background-size: 100% 100%;
  }

  .support_your_case {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 31px !important;
    line-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -2px;
    color: #1b4079;
  }

  .header_content h2 {
    font-family: "Roboto";
    font-size: 38px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: -3px;
    text-align: center;
    color: #ffffff;
  }

  .header_content p {
    font-family: " Roboto";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #f5f5f5;
  }

  .header-learn-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-learn-more-btn Button {
    width: 150.890625px;
    height: 58px;
    border-radius: 3px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 3.5999999046325684px;
    text-align: center;
    border: none;
    outline: none;
    color: #ffffff;
  }

  .nav-btn Button {
    height: 60px;
    padding: 4px 5px;
    border-radius: 3px;
  }

  .cc {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .my-slide-component {
    height: 350px !important;
    margin-left: 0px !important;
  }
  .footer-main-pic {
    min-height: 651.5px !important;
    width: 100%;

    /* border: 1px solid #000; */
    background-repeat: no-repeat;
    /* background-image: url("../assets//img//footer/MicrosoftTeams-image\ \(27\).png"); */
    background-size: cover !important;
  }

  .header-main {
    padding-top: 20px;
  }

  .header-learn-more-btn Button {
    width: 150.890625px;
    height: 58px;
    border-radius: 3px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 3.5999999046325684px;
    text-align: center;
    border: none;
    outline: none;
    padding: 10px;
    color: #ffffff;
  }

  .input-main .lebel {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #1b4079;
    margin-left: 4px;
  }

  .header-main {
    padding-top: 20px;
  }

  .img-fluid {
    width: 140%;
  }

  .ul {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 4px;
    margin-left: 2px;
    font-size: 20px;
    margin-left: -28px;
    padding-top: 14px;
  }

  .detail-content-box {
    display: flex;
    justify-content: center;
    gap: 28px;
  }

  .detail-container {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .main-img {
    background-image: url("../../public/header2.png");
    background-size: 100% 100%;
    width: 100%;
    min-height: 277px;
    background-repeat: no-repeat;
    background-position: top center;
    /* opacity: 80%; */
  }

  .row-card {
    display: flex;
    gap: 29px;
  }

  .popular-content {
    text-align: center;
    padding-left: 3px !important;
  }

  .popular-content h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 31px !important;
    line-height: 64px;
    display: flex;
    align-items: unset;
    letter-spacing: -0.2px;
    color: #1b4079;
    justify-content: center;
  }

  .popular-content p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #4d7c8a;
  }

  .popular-content {
    padding-left: 0;
    text-align: center;
  }

  /* mediaqueries about page  */

  .header-mission-pic {
    height: 317px !important;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover !important;
    /* background-image: url(http://localhost:3000/static/media/MicrosoftTeams-image%20(24).873e05a….png); */
    /* background-image: url(../assets//img//Our_mission//header.svg); */
  }

  .header-content h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    margin-top: 24px !important;
    letter-spacing: 1.6px;
    color: #ffffff;
  }

  .header-content p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 33px;
    color: #ffffff;
  }

  .header-content h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 31px !important;
    margin-top: 87px;
    letter-spacing: 1.6px;
    color: #ffffff;
  }

  .non-profit h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    line-height: 44px !important;
    color: #1b4079;
    text-align: center;
    margin-top: -156px !important;
  }

  .globian-main-content {
    display: flex;
    gap: 20px;
    text-align: center;
  }

  .ouragency-content h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 31px !important;
    line-height: 47px !important;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.2px;
    color: #ffffff;
    margin-top: -91px;
  }

  .what-we-do h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 39px !important;
    line-height: 64px;
    color: #1b4079;
    text-align: center !important;
  }

  .what-we-do p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #535353;
    text-align: center !important;
  }

  .globian-content h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 21px !important;
    line-height: 26px;
    color: #041d57;
  }
  .partner-btn {
    display: flex;
    justify-content: center;
  }

  .btn {
    width: 132.97px !important;
    height: 49.39px !important;
    color: #fff;
    background: #1e417b;
    border-radius: 3px;
    margin-bottom: 2px;
  }

  .globian-mobile-view {
    display: flex;
    justify-content: center;
  }

  .our-team-cards {
    display: flex;
    /* justify-content: space-between; */
    gap: 162px !important;
  }

  /* join ss ===================== */

  .join-Home {
    height: 248px !important;
    width: 100%;
    margin-top: -60px;
    background-repeat: no-repeat;
    background-size: cover !important;
  }

  .become-content {
    padding-left: 20px;
    text-align: center;
  }
  .become-content h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 62px;
    display: flex;
    align-items: center;
    color: #1b4079;
    margin-top: -49px;
  }

  .vol-img {
    display: flex;
    justify-content: center;
    padding-top: 1px !important;
    padding-left: 30px;
  }

  .Become-a {
    display: flex;
    justify-content: center;
  }

  .vol-img {
    display: flex;
    justify-content: center;
    padding-top: 478px;
    padding-left: 30px;
  }

  .cause-supo h1 {
    font-family: "Roboto";
    font-size: 31px !important;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 2px;
    text-align: left;
    color: #1b4079;
    margin-left: 30px;
  }

  .cause-ca {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    gap: 38px;
  }

  .header-mission-picc {
    height: 237px !important;
    width: 100%;
    margin-top: -60px;
    background-repeat: no-repeat;
    background-size: cover !important;
    /* background-image: url(http://localhost:3000/static/media/Slider%20(1).8a96a5e….png); */
  }

  .update-content h1 {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 33px !important;
    line-height: 1px;
    color: #1b4079;
  }

  .update-contentt p {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
    margin-top: -55px;
    color: #000000;
  }

  .conten-ccenter {
    text-align: center;
  }
  .width {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .volunter-row {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 20px;
    /* margin-bottom: 20px; */
  }

  .navbar-nav {
    width: 100%;
    display: flex;
    margin-left: -28px;
  }

  /* contact us================================ */

  .header-ContactUs-pic {
    height: 242px !important;
    width: 100%;
    margin-top: -60px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-image: url(http://localhost:3000/static/media/MicrosoftTeams-image%20(30).9ac5e6a….png); */
  }

  .Get-In-Touch h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 31px !important;
    padding-left: 10px;
    line-height: 19px;
    color: #1b4079;
  }

  .visit-office h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 19px !important;
    line-height: 32px;
    padding-left: 10px;
    padding-top: 35px;
    color: #222222;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .ul {
    display: flex;
    justify-content: center;
    width: 55%;
    gap: 0px;
    /* left: 20px; */
    margin-left: 0px;
    font-size: 20px;
  }

  .main-img {
    background-image: url("../../public/header.jpg");
    background-size: 100% 30%;
    min-height: 860px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    /* border-bottom-left-radius: 50px 20px;
  border-bottom-right-radius: 250px 90px;  */
    /* opacity: 80%; */
  }
  .contact-side-bar {
    display: flex;
    justify-content: first baseline;
    padding-left: 20px;
    background: #f5f5f5;
    align-items: center;
    margin-top: 20px;
    padding-top: 27px;
  }

  .header-content h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    margin-top: 90px !important;
    letter-spacing: 1.6px;
    color: #ffffff;
  }

  .header-mission-pic {
    height: 406px !important;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-image: url(http://localhost:3000/static/media/MicrosoftTeams-image%20(24).873e05a….png); */
    /* background-image: url(../assets//img//Our_mission//header.svg); */
  }

  .width {
  }

  .update-content h1 {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 34px !important;
    line-height: 56px;
    color: #1b4079;
  }

  .update-contentt p {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 17px !important;
    line-height: 28px;
    color: #000000;
    text-align: center;
    margin-top: -54px;
  }

  .card-content h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 38px;
    padding-left: 8px;
    color: #000000;
  }
}
