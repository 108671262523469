.globian-main {
  min-height: 500px;
  background: #f5f5f5;
  padding-top: 20px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .globian-main {
    margin: auto;
    padding-top: 70px;
  }
}
@media (min-width: 390px) and (max-width: 844px) {
  .globian-main {
    margin: auto;
    padding-top: 120px;
  }
}


.non-profit h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  color: #1b4079;
  text-align: center;
}
.globian-content h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #041d57;
}
.globian-main-content {
  display: flex;
  gap: 20px;
}
.globian-content p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #535353;
}
.blue-con {
  height: 20px;
  width: 20px;
  /* background: #1B4079; */
  background-image: url("../assets//img//about-page//blueicon.svg");
  background-size: 100% 100%;
}
.icon-and-content {
  display: flex;
  gap: 10px;
}
.popular-caurse-btn {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
}
