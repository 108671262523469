@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&family=Roboto&display=swap');


.cards-main {
    background-color: #F5F5F5;
}

.mission-card {
    width: 350px;
    background-color: #ffffff;
}

.cards-row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 40px;
    /* padding-left: -30px; */

}

.main-card {}

.main-card-content {
    min-height: 150px;
    padding-top: 15px;
    text-align: center;

    background-color: #ffffff;
}

.main-card-content h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1B4079;
}

.main-card-content p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 5px;


    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;


}


.card {
    border: none;
    outline: none;
    background: none;
}

.popular-content {
    padding-left: 70px;
}

.popular-content h3 {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    /* or 134% */

    display: flex;
    align-items: center;
    letter-spacing: -0.2px;

    color: #1B4079;

}

.popular-content p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    /* or 124% */

    display: flex;
    align-items: center;

    color: #000000;
}

.img-btnn {
    color: #ffffff;
    background: #1B4079;
    border-radius: 3px;
    height: 30px;

    width: 110px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
    margin-left: 20px;
    /* identical to box height, or 200% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;

    color: #F5F5F5;


}

.main-card-contentt {
    background-color: #ffffff;
}

.main-card-contentt p {
    padding-left: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* or 141% */

    display: flex;
    align-items: center;
    letter-spacing: 1.6px;

    color: #2B3539;
}


.Pledged-main h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    /* or 160% */

    display: flex;
    align-items: center;
    letter-spacing: 0.26px;
    text-transform: capitalize;

    color: #2B3539;

    margin-left: 8px;
    margin-top: 4px;
}

.goal-icon {
    height: 22px;
    width: 22px;
    margin-top: 5px;
    margin-left: 20px;
    /* background-color: #4D7C8A; */
    background-image: url('../assets/img//Our_mission//Goal.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.pledged-icon {

    height: 22px;
    width: 22px;
    margin-top: 5px;
    margin-left: 20px;
    /* background-color: #4D7C8A; */
    background-image: url('../assets/img//Our_mission/Pledged.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.pledged-content {
    padding-top: 1px;
}

.progrress {
    height: 10px;
    margin-left: 17px;
    /* background: #1E417B; */
    /* background: #000; */


}

.bg-SOME_NAME {
    background-color: #1B4079 !important;
}


.admin-pic {
    width: 30px;
    height: 30px;
    margin-left: 19px;

    background: url("../assets//img/Our_mission//avatar_6.jpg.png");
    border-radius: 39px;
}

.admin-main h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #535353;
    padding-left: 14px;
    padding-top: 7px;
}

.popular-caurse-btn {
    display: flex;
    justify-content: center;
    align-self: center;
}

.popular-caurse-btn .pupolar-btn {
    /* padding: 12px 29px !important; */
    height: 65px;
    border-radius: 3px;
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

}

.popular-caurse-btn .pupolar-btn:hover {
    background: #4D7C8A;
}