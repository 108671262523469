.trusted-main{
    min-height: 600px;

    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);
}

.partner-btn Button{
    border: none;
    outline: none;
}

.partner-btn Button:hover{
   background-color: #4D7C8A;
   border: none !important;
}