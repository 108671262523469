.join-Home {
  height: 560px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets//img/Join-ssf//Slider\ \(2\).png");
}
@media (min-width: 768px) and (max-width: 1023px) {
  .join-Home {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 390px) and (max-width: 844px) {
  .join-Home {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 360px) and (max-width: 740px) {
  .join-Home {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
.join-Home2 {
  height: 760px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets/img/renewal-spring-flourishing-flowers-revitalization_157027-2530.jpg");
}
/* src/assets/img/renewal-spring-flourishing-flowers-revitalization_157027-2530.jpg */
