/* Header Component Style */
@import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Inter:wght@300;500;700&family=Kanit:wght@300&family=Montserrat:wght@200;300&family=Poppins:ital,wght@0,700;1,400&family=Raleway&family=Roboto:wght@400;500;700;900&family=Ubuntu:ital,wght@0,500;0,700;1,500&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
}



Link {
  text-decoration: none;
  outline: none;
}
.navbar-nav{
  width: 100%;
}


.join-ssf-btn Button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;
  outline: none;
  
  /* padding: 18px 22px; */
}
.join-ssf-btn Button:hover{
  background-color:#4D7C8A;
  border: none;
  outline: none;
}



.navbar-brand{
  width: 300px;
  min-height: 60px;
 
}


.logo-div {
display: flex;
justify-content: flex-start;
}
.cccccc{
  display: flex;
  justify-content: center;
}

.pre_ul {
  width: 50%;
  /* display: flex; */
/* gap: 40px; */
  /* border: 1px solid #000; */
}


.nav-logo{
  background-size: 100% 100%;
}

.ul {
  display: flex;
  justify-content: center;
  width: 55%;
  gap: 10px;
  /* left: 20px; */
  margin-left: 50px;
  font-size: 20px;

}

.nav-btn {
  min-height: 40px;
}

.nav-btn Button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* padding: 17px 10px; */
  border-radius: 3px;
  border: none;
  outline: none;

}

.nav-btn Button:hover{
 background-color: #4D7C8A;
 border: none;
  outline: none;

}




.img-fluid {
  width: 60%;
}


.nav-link {


  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  margin-left: 30px;
  /* identical to box height, or 138% */

  display: flex !important;
  align-items: center !important;

  color: #4D7C8A !important;

}

.btn {
  width: 149.97px;
  height: 44.39px;
  color: #fff;
  background: #1E417B;
  border-radius: 3px;
}

.nav-link {}