@import url("https://fonts.googleapis.com/css2?family=Fasthand&family=Inter:wght@300;500;700&family=Kanit:wght@300&family=Montserrat:wght@200;300&family=Poppins:ital,wght@0,700;1,400&family=Raleway&family=Roboto:wght@500&family=Ubuntu:ital,wght@0,500;0,700;1,500&display=swap");

.whowe-main {
  background: #f5f5f5;
  min-height: 10px !important;
}
.who-we p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding-top: 100px;
}
.who-we h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -2px;

  color: #1b4079;
}
.content-box {
}

.whowe-img {
  width: 488px;
  height: 614px;
}
.ourstory-img {
  height: 100% !important;
  width: 100% !important;
}
.paragraph {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  line-height: 25px !important;
  /* or 138% */
  color: #535353 !important;
  letter-spacing: 0px;
  /* margin-top: 60px; */
}

.education-img {
  background-image: url("../assets/img/education.svg");
  width: 64px;
  height: 64px;
  background-size: 100% 100%;
}

.health-img {
  background-image: url("../assets/img/Health.svg");
  width: 64px;
  height: 64px;
  background-size: 100% 100%;
}

.food-img {
  background-image: url("../assets/img/Food.svg");
  width: 64px;
  height: 64px;
  background-size: 100% 100%;
}

.whowe-h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -1px;
  color: #000000;
}
.Emporwing {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -1px;
  color: #000000;
}

.read-more button {
  width: 149.97px;
  height: 58.39px;
  background: #1b4079;
  border-radius: 3px;
  margin-left: 104px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #f5f5f5;
  font-size: 13px;
  border: none;
}

.read-more button:hover {
  background: #4d7c8a;
  color: #f5f5f5;
}

.our-story-right-top {
  background: #4d7c8a !important;
}

.our-story-left-bottom {
  background: #4d7c8a !important;
}
.card-title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 48px !important;
  /* identical to box height, or 100% */

  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 2px !important;

  color: #ffffff !important;
}
