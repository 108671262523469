/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/
.gallery-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.gallery-card > img {
  width: 100%;
}

.gallery-card:hover .gallery-content {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.gallery-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: RGBA(var(--thm-primary-rgb), 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.gallery-content > a {
  font-size: 32px;
  color: #fff;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.gallery-content > a:hover {
  color: #fcad30;
}

.video-card {
  background-color: var(--thm-black);
  position: relative;
}

.video-card__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* -webkit-filter: grayscale(100%); */
  /* filter: grayscale(100%); */
  /* opacity: 0.5; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.video-card .container {
  position: relative;
}

.video-card .container p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fcad30;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  margin-bottom: 15px;
}

.video-card .container p img {
  margin-right: 10px;
}

.video-card .container h3 {
  margin: 0;
  color: #fff;
  letter-spacing: -0.04em;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 45px;
}

@media (max-width: 575px) {
  .video-card .container h3 {
    font-size: 40px;
  }
  .video-card .container h3 br {
    display: none;
  }
}

@media (max-width: 375px) {
  .video-card .container h3 {
    font-size: 30px;
  }
}

.video-card__btn-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-card__btn {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  margin-left: 30px;
  background-color: var(--thm-primary);
  color: #fff;
  cursor: pointer;
}

.video-card__btn:hover {
  color: var(--thm-primary);
  background-color: #fff;
}

.video-card-two {
  padding-top: 100px;
  position: relative;
  z-index: 11;
}

.video-card-two .inner-container {
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #242323;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 65px;
  padding-bottom: 20px;
  position: relative;
  border-bottom-left-radius: 145.5px;
  border-top-left-radius: 145.5px;
  z-index: 11;
}

@media (max-width: 991px) {
  .video-card-two .inner-container {
    padding-top: 40px;
    padding-bottom: 30px;
    text-align: center;
    padding-right: 20px;
    border-radius: 15px !important;
  }
}

.video-card-two h3 {
  margin: 0;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.05em;
  font-weight: bold;
  color: #fff;
  padding-left: 22px;
  position: relative;
  left: 30px;
}

@media (max-width: 1199px) {
  .video-card-two h3 {
    left: 0;
    font-size: 30px;
    line-height: 1.5;
  }
}

@media (max-width: 991px) {
  .video-card-two h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0;
  }
}

.video-card-two p {
  margin: 0;
  padding-left: 70px;
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
  color: #9e9e9e;
}

@media (max-width: 1199px) {
  .video-card-two p {
    padding-left: 0;
  }
}

.video-card-two__box {
  display: inline-block;
  position: relative;
}

@media (max-width: 991px) {
  .video-card-two__box {
    width: 251px;
    height: 251px;
    margin-left: auto;
    display: block;
    margin-right: auto;
  }
}

.video-card-two__box > img {
  border-radius: 50%;
}

@media (max-width: 1199px) {
  .video-card-two__box > img {
    max-width: 100%;
  }
}

.video-card-two__box-btn {
  width: 56px;
  height: 56px;
  /* background-color: ; */
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  cursor: pointer;
}

.video-card-two__box-btn:hover {
  color: #fff;
  background-color: var(--thm-primary);
}

@media (max-width: 575px) {
  .video-card-two__box-btn {
    top: 50%;
    right: auto;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
}

.contact-card {
  border-top-left-radius: 220.5px;
  border-top-right-radius: 220.5px;
  background-repeat: no-repeat;
  width: 100%;
  background-position: top right;
  min-height: 442px;
}

.contact-card i {
  width: 164px;
  height: 164px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-black);
  font-size: 64px;
  background-color: #fff;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.contact-card h3 {
  color: #fff;
  font-size: 18px;
  margin: 0;
  font-weight: bold;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 25px;
}

@media (min-width: 481px) {
  .contact-card h3 {
    font-size: 22px;
  }
}

.contact-card p {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}

.contact-card p a {
  color: inherit;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.contact-card p a:hover {
  color: var(--thm-black);
}

.event-card {
  -webkit-box-shadow: 0px 10px 60px 0px RGBA(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px RGBA(0, 0, 0, 0.05);
  border-radius: 15px;
  border: 1px solid transparent;
  -webkit-transition: 500ms;
  transition: 500ms;
  background-color: #fff;
}

.event-card:hover {
  border-color: #e4e4e4;
  -webkit-box-shadow: 0px 10px 60px 0px RGBA(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px RGBA(0, 0, 0, 0.1);
}

.event-card:hover .event-card-inner::before {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.event-card-inner {
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
}

@media (min-width: 1200px) {
  .event-card-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.event-card-inner::before {
  content: "";
  width: 295px;
  height: 291px;
  border: 3px solid red;
  /* background-image: url(../images/shapes/event-line-1-1.png); */
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(55%, -40%);
  transform: translate(55%, -40%);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  -webkit-transition: 500ms;
  transition: 500ms;
}

@media (min-width: 575px) {
  .event-card-inner {
    padding: 40px;
  }
}

.event-card-image-inner {
  position: relative;
  margin-bottom: 30px;
  border-radius: 50%;
  background-color: var(--thm-black);
}

@media (min-width: 1200px) {
  .event-card-image-inner {
    margin-bottom: 0;
    margin-right: 55px;
  }
}

.event-card-image-inner span {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-color: #fcad30;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}

.event-card-image-inner > img {
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.event-card:hover .event-card-image-inner > img {
  opacity: 0.5;
}

.event-card-list {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
}

.event-card-list li {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
}

.event-card-list li strong {
  font-weight: 500;
  color: var(--thm-black);
}

.event-card-list li > i {
  font-size: 16px;
  color: var(--thm-secondary);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.event-card-content {
  position: relative;
}

.event-card-content h3 {
  font-size: 20px;
  color: var(--thm-black);
  font-weight: bold;
  margin: 0;
  letter-spacing: -0.04em;
}

@media (min-width: 481px) {
  .event-card-content h3 {
    font-size: 24px;
  }
}

.event-card-content h3 a {
  color: inherit;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.event-card-content h3 a:hover {
  color: var(--thm-primary);
}
