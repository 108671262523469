@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap');




.ourAgency-main{
    min-height: 480px;
    background-image: url("../assets/img/about-page//OurAgency.png");
     background-size: 100% 100%;
}
.ouragency-content h2{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 50px;
/* or 105% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.2px;

color: #FFFFFF;
}
.ouragency-content h4{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 66px;
color: #FFFFFF;

}
.agnecy-icon{
 height: 100px;
}

.bb{
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-wrap: wrap;

}