@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap');

.volunter-main {

    min-height: 510px;
    background-image: url("../assets/img//Our_mission/Rectangle\ 142.png");
    background-size: 100% 100%;
}

.counter-container {
    min-height: 530px;

    display: flex;
    align-items: center;
}

.box-bg-color {
    width: 300px;
    height: 300px;


    background: rgba(77, 124, 138, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.volunter-row {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 20px;

}

.box-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.donor {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets//img//Our_mission//donor.svg');
}

.volun-pic {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets//img//Our_mission//volunter.svg');

}

.call-pic {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets//img//Our_mission/free-call.svg');

}


.box-content h4 {

    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;

}

.box-content p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    color: #FFFFFF;

}

.box-content button{
   outline: none !important;
  border:none !important;
    
}

.box-content button:hover{
     background-color:#4D7C8A;   

    /* border:none !important; */
      
  }

  