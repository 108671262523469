@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&family=Roboto&display=swap");

.header-mission-picc {
  height: 600px;
  width: 100%;
  margin-top: -35px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets/img/chirstmas-mission.png");
}
.header-mission-picc_jer {
  height: 630px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets/img/jer/WhatsApp Image 2023-08-20 at 5.31.03 AM.jpeg");
}
.header-mission-picc_jer4 {
  height: 700px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets/img/mission-christmas/mission-christmas-banner.png");
}
.header-mission-picc_jer2 {
  height: 700px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets/img/jer/orphans.png");
}
.header-mission-picc_jer1 {
  height: 800px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-image: url("../assets/img/jer/cooler-provide1.jpg");
}
.header-mission-Tobatek {
  height: 800px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-image: url("../assets/img/TobaTek/banner-toba-tek.jpeg");
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header-mission-picc {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 390px) and (max-width: 844px) {
  .header-mission-picc {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 360px) and (max-width: 740px) {
  .header-mission-picc {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header-mission-Tobatek {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 390px) and (max-width: 844px) {
  .header-mission-Tobatek {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 360px) and (max-width: 740px) {
  .header-mission-Tobatek {
    width: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}

.header-content {
  min-height: 100px;
}

.header-content h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  margin-top: 190px;

  /* identical to box height or 173% */

  letter-spacing: 1.6px;

  color: #ffffff;
}

.header-content p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* or 150% */
  color: #ffffff;
}

.header-content h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.main-cards {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .header-mission-picc_jer4 {
    height: 450px;
    width: 100%;
    background-size: cover;
    background-size: 100% 100%;
  }
}
