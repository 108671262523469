.NewUpdateDetail1 {
  height: 600px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets//img/New-update-detail/blog-image-1.jpg");
}
.NewUpdateDetail {
  height: 600px;
  width: 100%;
  margin-top: -60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets//img/New-update-detail/MicrosoftTeams-image (38).png");
}

.detail-content-box {
  display: flex;
  justify-content: center;
}
.detail-container {
  margin-top: 70px;
  margin-bottom: 60px;
}
