/* Importing all CSS files */
@import url("./Header.css");
@import url("./HeroSection.css");
@import url("../css//mediaqueries.css");
@import url("../css/whowe.css");
@import url("../css/support.css");
@import url("../css/OurStory.css");
@import url("../css/newUpdate.css");
@import url("../css/footer.css");
@import url("../css//urmission.css");
@import url("../css//missionCards.css");
@import url("../css/./volunter.css");
@import url("../css//aboutHome.css");
@import url("../css//aboutGlobian.css");
@import url("../css//ourAgency.css");
@import url("../css/WhatWeDo.css");
@import url("../css/ourTeam.css");
@import url("../css//trusted.css");
@import url("../css//ContactUs.css");
@import url("../css/joinHome.css");
@import url("../css//becomeVolunter.css");
@import url("../css//NewUpdateDetail.css");
@import url("../css/firstPage.css");
/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}
a {
  text-decoration: none !important;
}
/* Primary Variables */
:root {
  --primary: "green";
}

body {
  background-color: #f5f5f5 !important;
}
.my-slide-component {
  width: 479px !important;
  height: 558px;
  /* user-select: none; */
  /* border: 1px solid #000000 !important; */
  border-radius: 36px !important;

  margin-left: 120px;
}

.our-client {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  /* or 100% */

  /* display: flex !important; */
  /* align-items: center !important; */
  text-transform: uppercase !important;

  color: #000000 !important;
}
.donation_Card_text {
  color: #1b4079 !important;
  justify-content: center;
}

.our-client-hr {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  /* or 100% */

  position: relative;
  left: 217px;
  /* display: flex !important; */
  /* align-items: center !important; */
  text-transform: uppercase !important;
  width: 200px;
  text-align: center;
  height: 1.5px !important;
  border-top: 1px solid #e8e8e8 !important;
}
.sponsor-section {
  position: relative;
  top: 100px;
}

.iASUtr {
  display: flex !important;
  flex-direction: row !important;
  width: 80% !important;
}

.support_your_case {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;
  /* or 100% */

  display: flex;
  align-items: center;
  letter-spacing: -2px;

  color: #1b4079;
}

.paypal_donate {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 11px 24px;
  background: #1e417b;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  transition-duration: 0.2s;
  margin-left: 8px;
  outline: none;
}

/* Set the slider height for iPhone 12 */
@media (max-width: 375px) {
  .carousel .carousel-item img {
    height: 500px;
  }
  .carousel .carousel-item .overlay {
    height: 500px;
  }
}

@media (max-width: 575px) {
  .paypal_donate {
    margin-left: 45px;
  }
}

/* Set the slider height for iPhone 13 */
@media (min-width: 376px) and (max-width: 414px) {
  .carousel .carousel-item img {
    height: 600px;
  }
  .carousel .carousel-item .overlay {
    height: 600px;
  }
}

/* Set the slider height for iPhone 13 Pro Max */
@media (min-width: 415px) and (max-width: 428px) {
  .carousel .carousel-item img {
    height: 650px;
  }
  .carousel .carousel-item .overlay {
    height: 650px;
  }
}

/* Set the slider height for iPhone 14 Pro Max */
@media (min-width: 429px) {
  .carousel .carousel-item img {
    height: 700px;
  }
  .carousel .carousel-item .overlay {
    height: 700px;
  }
}

.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.photo {
  margin: 10px;
  cursor: pointer;
}

.photo img {
  width: 400px;
  height: 250px;
  object-fit: cover;
  border: 1px solid #ccc;
}

.selected-photo {
  margin-top: 20px;
  text-align: center;
}

.selected-photo img {
  max-width: 100%;
  max-height: 400px;
}
.main-slider {
  overflow: hidden;
  position: relative;
}
.main-slider {
  overflow: hidden;
  position: relative;
}

.main-slider .swiper-slide {
  position: relative;
  background-color: #151414;
}

.main-slider .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7 !important;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 7000ms ease;
  transition: -webkit-transform 7000ms ease;
  transition: transform 7000ms ease;
  transition: transform 7000ms ease, -webkit-transform 7000ms ease;
}

.main-slider .container {
  padding-top: 370px;
  padding-bottom: 180px;
}
@media (width: 360px) {
  .image-layer-img {
    width: 100% !important;
    height: 100% !important;
    background-size: 100% 100% !important;
    background-size: cover;
  }
}
@media (min-width: 390px) and (max-width: 844px) {
  .image-layer-img {
    width: 100% !important;
    background-size: 100% 100% !important;
    height: 100% !important;
    background-size: cover;
  }
}
@media (min-width: 360px) and (max-width: 740px) {
  .image-layer-img {
    width: 100% !important;
    background-size: 100% 100% !important;
    height: 100% !important;
    background-size: cover;
  }
}

@media (max-width: 1199px) {
  .main-slider .container {
    padding-top: 180px;
  }
}

@media (max-width: 575px) {
  .main-slider .container {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

.main-slider p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--thm-base);
  opacity: 0;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 0;
  transition-delay: 0;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

.main-slider h2 {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 100px;
  line-height: 1.1;
  font-weight: bold;
  letter-spacing: -0.04em;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(90px);
  transform: translateY(90px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

@media (max-width: 1199px) {
  .main-slider h2 {
    font-size: 80px;
  }
}

@media (max-width: 991px) {
  .main-slider h2 {
    font-size: 60px;
    line-height: 1.3;
  }
}

@media (max-width: 575px) {
  .main-slider h2 {
    font-size: 50px;
  }
}

@media (max-width: 375px) {
  .main-slider h2 {
    font-size: 36px;
  }
}

.main-slider .thm-btn {
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
}

.main-slider .swiper-slide-active .image-layer {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.main-slider .swiper-slide-active .thm-btn,
.main-slider .swiper-slide-active h2,
.main-slider .swiper-slide-active p {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

#main-slider-pagination {
  /* z-index: 10 !important;
  bottom: auto !important;
  top: 50% !important;
  left: 20% !important;
  width: 100% !important;
  max-width: 1170px !important; 
  border: 3px solid red !important;
  -webkit-transform: translate(-50%, calc(-50% + 95px)) !important;
  transform: translate(-50%, calc(-50% + 95px)) !important;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; */
}

@media (max-width: 1199px) {
  #main-slider-pagination {
    max-width: 960px;

    -webkit-transform: translate(-50%, calc(-50% + 40px));
    transform: translate(-50%, calc(-50% + 40px));
  }
}

@media (max-width: 575px) {
  #main-slider-pagination {
    display: none;
  }
}

#main-slider-pagination .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  border-radius: 0;
  border-top-right-radius: 8.5px;
  border-bottom-right-radius: 8.5px;
  opacity: 0.4;
  background-color: #fff;
}

#main-slider-pagination .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-top: 10px;
}

#main-slider-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.main-slider__two .container {
  padding-top: 250px;
  padding-bottom: 170px;
}

@media (max-width: 1199px) {
  .main-slider__two .container {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

@media (max-width: 575px) {
  .main-slider__two .container {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.main-slider__two h2 {
  font-size: 70px;
  line-height: 80px;
  letter-spacing: -0.04em;
  color: #fff;
  margin: 0;
}

.main-slider__two h2 span {
  position: relative;
}

.main-slider__two h2 span::before {
  content: "";
  width: 58px;
  height: 50px;
  /* background-image: url(../images/main-slider/heart-1-1.png); */
  position: absolute;
  bottom: 70%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: 100%;
  background-repeat: no-repeat;
}

@media (max-width: 375px) {
  .main-slider__two h2 span::before {
    width: 40px;
    height: 35px;
  }
}

@media (max-width: 1199px) {
  .main-slider__two h2 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .main-slider__two h2 {
    font-size: 50px;
    line-height: 1.3;
  }
}

@media (max-width: 575px) {
  .main-slider__two h2 {
    font-size: 45px;
  }
}

@media (max-width: 375px) {
  .main-slider__two h2 {
    font-size: 30px;
  }
}

.main-slider__two p {
  margin: 0;
  color: #fff;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  margin-top: 25px;
  margin-bottom: 40px;
}

.main-slider__two #main-slider-pagination {
  left: auto;
  right: 50%;
  text-align: right;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: translate(50%, calc(-50% + 0px));
  transform: translate(50%, calc(-50% + 0px));
}
/* ChristmasPopup.css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #aaa;
}

.christmas-image {
  max-width: 100%;
  margin-top: 10px;
}
