.what-Wedo{
    min-height: 530px;
    background: #F5F5F5;
}


.what-we-do h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    color: #1B4079;
}
.what-we-do p{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 30px;
color: #535353;
}

.partner-btn{
    
}