@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap');

.cc{
    
}




.footer-main-pic{
    min-height: 600.5px;
    width: 100%;

    /* border: 1px solid #000; */
    background-repeat: no-repeat;
    background-image: url("../assets//img//footer/MicrosoftTeams-image\ \(27\).png");
    background-size: 100% 100%;
}
.input-content{
    display:flex ;
    justify-content: center;
}

.footer h3{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 24px;

text-transform: uppercase;

color: #000000;

}
.footer p{
    
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */
    

    color: #000000;

}


.main-input{
    background:white;
    width: 600px;
    display: flex;
    justify-content: space-between;
    border-radius: 23px;

}
.main-input input{
    width: 80%;
    height: 48px;
    border-radius: 23px;
    padding-left: 15px;
    outline: none;
    border: none;
    /* box-shadow:  1px 2px 5px black; */
}
.main-input button{
    padding: 10px 30px;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;    border-radius: 23px;

font-size: 12px;

    /* color: aliceblue; */
display: flex;
align-items: center;
text-align: center;
text-transform: uppercase;
color: #1B4079;


}
.main-input button:hover{
    background-color: #1B4079;
    color: aliceblue;
    border-radius: 23px;

}
.footer ul{

    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 30px;

}
.footer ul li{
font-family: 'Plus Jakarta Sans'!important;
font-style: normal;
font-weight: 700;
font-size: 14px;

cursor: pointer;
display: flex;
align-items: center;
text-align: center;
text-transform: uppercase;

color: #000000;

}
.footer h5{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 29px;
/* identical to box height, or 173% */

text-align: center;

color: #000000;
    padding-top: 6px;
}
.media{
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 15px;
}

.media a{
    height: 50px;
    width: 50px;
    background: #CCCCCC;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.media a i{
    text-decoration: none;
    outline: none;
    border: none;
    color: white;
    font-size: 20px;
}
.media .facebook{
    background-color: #1877F2;
    padding-top: 7px;
    box-sizing: border-box;
}
.media .twitter{
    background-color: white;
    /* color: #1B4079; */
}
.media .twitter i{
    background-color: white;
    color: #1DA1F2;
}
.media .instagram{
    vertical-align: middle;
    padding-top: 7px;
    box-sizing: border-box;
  background: #d6249f;
  background: radial-gradient( at 10% 100%, #fdf497 3%, #fdf497 8%, #fd5949 10%,#d6249f 60%,#285AEB 99.9%);
}
.media .youtube{
    background-color: red;
    padding-top: 7px;
    box-sizing: border-box;
}