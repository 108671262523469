@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:ital,wght@0,300;0,400;0,500;1,700&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&family=Roboto:ital,wght@0,400;0,500;1,400&display=swap");

.view-all {}

.conten-ccenter {}

.update-main {
  min-height: 670px;
  background: #ffffff;
}

.update-content {
  width: 500px;
}

.width {
  width: 250px;
}

.update-content h1 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;

  color: #1b4079;
}

.update-contentt {
  width: 760px;
}

.update-contentt p {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 39px;
  color: #000000;
}

.update-contentt button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px 33px;
  background: #1e417b;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  transition-duration: 0.2s;
}

.update-contentt button:hover {
  background-color: #4d7c8a;
  color: #ffffff;
}

.cause-ca {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.progress {
  margin-left: 8px;
}

.cause-supo h1 {
  font-family: "Roboto";
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 2px;
  text-align: left;
  color: #1b4079;
  margin-left: 30px;
}

.update-card {
  width: 390px;
}

.row-card {
  display: flex;
  gap: 70px;
}

.date-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 21px;
  color: rgba(17, 24, 39, 0.6);

  background: rgba(31, 41, 55, 0.04);
  border-radius: 21.3333px;
}

.date-content {
  padding-left: 8px;
}

.date-content h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18.6667px;
  margin-top: 4px;
  padding-left: 10px;
  color: rgba(225, 228, 235, 0.6);
}

.card-content h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  padding-left: 8px;
  color: #000000;
}

.make {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  padding-left: 8px;
  color: #000000;
}

.card-content p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #535353;
  padding-top: 10px;
  padding-left: 8px;
}

.donate-btn h5 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */

  color: #000000;
}

.card-content button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px 24px;
  background: #1e417b;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  transition-duration: 0.2s;
  margin-left: 8px;
  outline: none;
}

.card-content button:hover {
  background-color: #4d7c8a;
  color: #ffffff;
}

/* ============cause-style===============================0 */

.progress-bar {
  border-radius: 32px;
  background: #1b4079;
}

.donate-btn button {
  padding: 11px 22px;
  border-radius: 7px;
  transition-duration: 0.2s;
}

.donate-btn button:hover {
  background-color: #4d7c8a;
  color: #ffffff;
}

.donate-img {
  display: flex;
  align-items: end;
  padding: 12px;
  border-top-left-radius: 30px 30px;
  border-top-right-radius: 30px 30px;
  height: 220px;
  /* border: 1px solid #000; */
  background-image: url("../assets//img//New_Header_Pic/eduction.png");
  background-size: 100% 100%;
}

.donate-img2-2 {
  display: flex;
  align-items: end;
  padding: 12px;
  border-top-left-radius: 30px 30px;
  border-top-right-radius: 35px 35px;
  height: 220px;
  /* border: 1px solid #000; */
  background-image: url("../assets/img/pump.png");
  background-size: 100% 100%;
}

.donate-img2 {
  display: flex;
  align-items: end;
  padding: 12px;
  border-top-left-radius: 30px 30px;
  border-top-right-radius: 35px 35px;
  height: 220px;
  /* border: 1px solid #000; */
  background-image: url("../assets/img/jer/WhatsApp Image 2023-08-20 at 5.31.03 AM.jpeg");
  background-size: 100% 100%;
}

.donate-img3 {
  display: flex;
  align-items: end;
  padding: 12px;
  border-top-left-radius: 30px 30px;
  border-top-right-radius: 35px 35px;
  height: 220px;
  /* border: 1px solid #000; */
  background-image: url("../assets/img//New_Header_Pic/khana1.jpg");
  background-size: 100% 100%;
}

.img-btn {
  width: 100px;
  height: 31px;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;

  text-transform: uppercase;
  background: #1b4079;
  border-radius: 6px;
}

.donate-img button:hover {
  background-color: #4d7c8a;
  color: #ffffff;
}

.donate-img2 button:hover {
  background-color: #4d7c8a;
  color: #ffffff;
}

.donate-img3 button:hover {
  background-color: #4d7c8a;
  color: #ffffff;
}

.content-bg-color {
  padding-left: 10px;
  min-height: 215px;
  background-color: white;
}

.donate-btn p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #535353;
}

.donate-btn h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1b4079;
}