@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&family=Roboto:wght@500;700&display=swap');


.last-detail-page p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: black;
}

.last-detail-page h1{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 34px;
color: #1B4079;

}

.last-detail-page h3{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 17px;
color: #1B4079;
padding-top: 10px;
}


.last-detail-page h2{
    font-family: 'Poppins';
font-style: normal;
font-weight:600;
font-size: 23px;
line-height: 17px;
color: #1B4079;
padding-top: 25px;
margin-bottom: 20px;

}



.last-detail-page h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1B4079;
    padding-left: 20px;
}
.side-border{
    min-height: 10px;
    width: 99%;
    border-left: 5px solid #1B4079;
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 26px;
color: #1B4079;
}

.blue-con2{

    height: 14px;
    width: 14px;
    /* background: #1B4079; */
    background-image: url('../assets//img//about-page//blueicon.svg');
    background-size: 100% 100%;
    margin-top: 5px;
}

.icon-with-content-2{
    margin-left: 10px;
    
}
.last-detai p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #535353;
}

.Social-Share-media a i{
    color: #1B4079;

}
.how-small h5{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 25px;
color: #4D7C8A;

}
.how-small p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 22px;
color: #4D7C8A;
}

.next-post{
    margin-left: 5px;
    padding-top: -10px;
}
.next-postt{
    padding-top: 2px;
}
.Previous-post{
    padding-top: 4px;
    padding-left: 5px;
}

.Leave-Reply{
    margin-top: -19px;
    background: transparent;
}
.Leave-Reply .Form-Control1{
    height: 50px;
    border: 1px solid #E2E1E5;
    border-radius: 5px;
    background: transparent;
    
}
.Leave-Reply .Form-Controlll{
    background: transparent;

}

.leave-p{
    padding-left: 20px;
}
.comment-btn button{
 padding: 21px 22px;
left: 3px;
top: calc(50% - 54px/2 + 214.27px);
border: none;
background: #1E417B;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
border-radius: 11px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 12px;
/* identical to box height, or 100% */
display: flex;
align-items: center;
text-align: center;
letter-spacing: 1px;
text-transform: uppercase;

color: #FFFFFF;

}

.input-last-section{
/* margin-bottom: 0px; */
    background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%);
}