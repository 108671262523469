.become-vol-main-header{
    min-height: 890px;
    background: white;

}


.become-Volunter{
    background-color: rgb(169, 61, 61);
}
.become-row{
    display: flex;
   justify-content: end;

   width: 99.7%;

}
.become-content{
    padding-left: 20px;
}
.become-content h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 62px;
    /* or 130% */
    
    display: flex;
    align-items: center;
    
    color: #1B4079;
    
}
.become-content p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */
    
    display: flex;
    align-items: center;
    
    color: #555555;
    
}

.main-info-bg{
    background-color:white;
}

.input-main .lebel{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 17px;
color: #1B4079;

}
.input-main .Form-Control{
    height: 47px;
    background: #FFFFFF;
    border: 1px solid #E2E1E5;
    border-radius: 5px;


    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 34px;
color: #1B4079;

}
.input-main .Form-Controll{
    height:100px;
    background: #FFFFFF;
    border: 1px solid #E2E1E5;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #1B4079;
}
.Become-a{

}
.vol-img{
    
}