@import url("https://fonts.googleapis.com/css2?family=Fasthand&family=Inter:wght@300;500;700&family=Kanit:wght@300&family=Montserrat:wght@200;300&family=Poppins:ital,wght@0,700;1,400&family=Raleway&family=Roboto:wght@500&family=Ubuntu:ital,wght@0,500;0,700;1,500&display=swap");

.ourstory-img {
  width: 0%;
  height: 400px;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}

.card-text_p_support {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
}

.rowww {
  padding: 0;
  margin: 0;
}

.out-storyy {
  background: #4d7c8a;
}

.out-storyy {
}

.main-cc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contentt-cc h2 {
  font-family: "Roboto";
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
}

.contentt-cc p {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ffffff;
}
