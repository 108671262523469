.our-Team{
    min-height: 780px;
}


.bg-color{
    display: flex;
    justify-content: center;
    align-items: center;
margin-top: -20px;
position: absolute;
width: 260px;
height: 140px;
margin-left: -20px;
background-image: url('../assets/img/about-page/Vector\ \(22\).png');
}
.bg-color1{
    display: flex;
    justify-content: center;
    align-items: center;
margin-top: -20px;
position: absolute;
width: 260px;
margin-left: -20px;
height: 140px;
background-image: url('../assets/img/about-page/Vector\ \(23\).png');
}

.our-team-cards{
    display: flex;
    /* justify-content: space-between; */
    gap: 0px;
}
.bg-color22 h3{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 26px;
color: #FFFFFF;

}
.bg-color22 p{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;
color: #FFFFFF;
}
.globian-mobile-view{
    
}